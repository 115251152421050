import React from 'react';
import { graphql } from 'gatsby';

import { Header, HeadlineTP, BodyTP, Title } from '../styles';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';

const Terms = ({ data }) => {
	const terms = data.allGoogleTermsSheet.edges;

	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	return (
		<Layout>
			<MetaData
				title="Envel - Terms"
				description={`Terms of Service for Envel - ${metaDescription}`}
				image="images/social/social.png"
			/>

			<Header center>Terms</Header>
			{terms.map(({ node: { title, content } }) => (
				<div key={title}>
					{title === 'Intro' ? (
						<Title font="22px">Introduction</Title>
					) : (
						<HeadlineTP dangerouslySetInnerHTML={{ __html: title }} />
					)}
					<BodyTP dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			))}
		</Layout>
	);
};

export default Terms;

export const termsQuery = graphql`
	query {
		allGoogleTermsSheet {
			edges {
				node {
					title
					content
				}
			}
		}
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
				}
			}
		}
	}
`;
